<template>
  <div class="profile__wrapper">
    <div class="" v-if="isVoted">
      <h5 class="title-h5 profile__info-title">
        {{ $t('categorization.categorization_vote_counted') }} {{ votingData }}
      </h5>
      <div class="profile__info">
        {{ $t('categorization.categorization_voting_info.txt_0') }}
        <span class="profile__link" @click="preliminaryResultsHandler">{{
          $t('categorization.categorization_voting_info.link')
        }}</span>
        {{ $t('categorization.categorization_voting_info.txt_1') }}
      </div>
    </div>

    <template v-if="structure">
      <div class="categorisation categorisation__wrapper">
        <div
          class="profile__section"
          :style="{ order: group.order_position }"
          v-for="(group, id_0) in structure"
          :key="`${group.uuid}${id_0}`"
        >
          <h4 class="title-h4 profile__title" v-if="!!group.title">
            {{ group.title }}
          </h4>
          <div
            class="categorisation__block"
            :style="{ order: subgroup.order_position }"
            v-for="(subgroup, id_1) in group.subgroups"
            :key="`${subgroup.uuid}${id_1}`"
          >
            <h5 class="title-h5 profile__subtitle" v-if="!!subgroup.title">
              {{ subgroup.title }}:
            </h5>
            <div
              :class="[
                subgroup.criterias[0].type === 'images'
                  ? 'image__grid'
                  : 'profile__grid',
              ]"
            >
              <div
                v-for="(item, id_2) in subgroup.criterias"
                :key="`${item.uuid}${id_2}`"
                :class="`${item.classnames}`"
                :style="{ order: item.order_position }"
                :id="item.uuid"
              >
                <text-field
                  v-if="['text', 'number', 'rating'].includes(item.type)"
                  v-model="item['value']"
                  :is-error="item.error == true"
                  :stars-read-only="editIsDisabled(item.uuid)"
                  :title="item.title"
                  :is-rate="item.type === 'rating'"
                  :is-icon="item.type === 'text_icon'"
                  :value-mask="item.mask"
                  :is-inactive="editIsDisabled(item.uuid)"
                  icon-name="actual"
                  :can-comment="canComment"
                  :is-commentable="commentsVisible"
                  :uuid="item.uuid"
                />
                <FileUpload
                  v-else-if="['file', 'files'].includes(item.type)"
                  :id="item.uuid"
                  v-model="item['value']"
                  :title="item.title"
                  :is-multiple="item.type == 'files'"
                  :is-download-only="editIsDisabled(item.uuid)"
                  :is-commentable="commentsVisible"
                  :uuid="item.uuid"
                  :can-comment="canComment"
                />
                <PhotoUpload
                  v-else-if="['images'].includes(item.type)"
                  :id="item.uuid"
                  :uuid="item.uuid"
                  v-model="item['value']"
                  :photos-uploaded="item['value']"
                  :title="item.title"
                  is-multiple
                  :is-commentable="commentsVisible"
                  :is-disabled="editIsDisabled(item.uuid)"
                  :can-comment="canComment"
                />
                <div
                  :class="`${item.classnames}`"
                  v-else-if="item.type === 'switcher'"
                >
                  <Switcher
                    :uuid="item.uuid"
                    :can-comment="canComment"
                    :is-commentable="commentsVisible"
                    :is-inactive="true"
                    v-model="item.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="voteSchedule !== 'none'" class="voting-date__block">
        <div v-if="voteSchedule === 'edit'" class="voting-date__block">
          <h4 class="title-h4 profile__title">
            {{ $t('categorization.voting_date_title') }}
          </h4>
          <p class="subtitle">
            {{ $t('categorization.voting_date_subtitle') }}
          </p>
        </div>
        <div v-if="voteSchedule === 'view'" class="voting-date__block">
          <h4 class="title-h4 profile__title">
            {{ $t('categorization.voting_date_title_view') }}
          </h4>
          <p class="subtitle">
            {{ $t('categorization.voting_date_subtitle_view') }}
          </p>
        </div>
        <FileUpload
          v-model="uploadedSchedule"
          class="schedule__upload"
          :is-multiple="false"
          :valid-formats="['doc', 'docx', 'pdf', 'img', 'jpeg', 'jpg', 'png']"
          :is-download-only="voteSchedule == 'view'"
          :max-size-k-b="25000"
          :is-error="this.$v.uploadedSchedule.$error"
          @dropError="uploadedScheduleError = false"
        />
        <div class="">
          <DatePicker
            v-model="votingDate"
            :format="'DD-MM-YYYY'"
            value-type="YYYY-MM-DD HH:mm:ss"
            :lang="getDateConfig"
            :disabled="voteSchedule == 'view'"
            :disabled-date="disabledDates"
            :placeholder="$t(`categorization.voting_date_placeholder`)"
            @input="votingDateError = false"
          />
          <div v-if="this.$v.votingDate.$error" class="error-message">
            <template v-if="votingDateErrorText">
              {{ votingDateErrorText }}
            </template>
            <template v-else>
              {{ $t('error.required_field') }}
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <LoaderContent />
    </template>
    <div class="no-print">
      <ButtonsBlock
        :buttons="buttonsArray"
        :disabledSign="ifBeforeOrToday || !isUserHasEuKey"
        :disabledVote="isAfterToday"
        :disabledDownLoad="!isCertificate"
        :disabledAchive="!isAchive"
        @print="print()"
        @downloadCertificate="downloadCertificate()"
        @signAndSend="signAndSendHandler()"
        @changeDate="changeDateHandler()"
        @preliminaryResults="preliminaryResultsHandler()"
        @vote="voteHandler()"
        @downloadPDF="downloadPDF()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import datePickerConfig from '@/library/date-picker-config';
import {
  checkIsSameDate,
  checkIsDateAfterToday,
  checkIsDateSameOrBeforeToday,
  convertDateToCurrentTimeZone,
} from '@/library/time';
import { getCategorizationFormStructure } from '@/api/services/hotel.js';
import FileUpload from '@/elements/FileUpload.vue';
import PhotoUpload from '@/elements/PhotoUpload.vue';
import ButtonsBlock from '@/elements/ButtonsBlock.vue';
import Switcher from '@/elements/Switcher.vue';
import LoaderContent from '@/elements/LoaderContent.vue';

export default {
  components: {
    FileUpload,
    PhotoUpload,
    DatePicker,
    ButtonsBlock,
    Switcher,
    LoaderContent,
  },
  data() {
    return {
      buttonsArray: [],
      commentsVisible: false,
      canComment: false,
      canEdit: '',
      voteSchedule: '',
      dataToSend: new FormData(),
      uploadedSchedule: [],
      votingDate: '',
      votingDateError: false,
      uploadedScheduleError: false,
      votingDateErrorText: '',
      structure: null,
    };
  },

  async created() {
    let categorizationRequestUUUID = this.$route.params.id;
    await this.$store.dispatch(
      'getCategorizationPresiding',
      categorizationRequestUUUID
    );

    this.$store.commit(
      'SET_CURRENT_FORM_TYPE',
      'categorization-presiding-commissioner'
    ); // for key-sign flow
    await this.getCategorizationStructure();

    this.statusModelHandler();

    if (this.commentsVisible) {
      await this.$store.dispatch(
        'getCommentsPresiding',
        categorizationRequestUUUID
      );
    }
    this.populateFormWithData();
    this.getScheduleData();
  },

  computed: {
    ...mapGetters([
      'getCategorizationPresiding',
      'getCommentsPresiding',
      'getStatusModel',
      'isUserHasEuKey',
    ]),

    prefilledCriteris() {
      let prefilledCriteris = {};
      this.getCategorizationPresiding?.criteria?.forEach((criteria) => {
        prefilledCriteris[criteria.categorization_criterion_uuid] = criteria;
      });
      return prefilledCriteris;
    },

    ifToday() {
      return checkIsSameDate(this.getCategorizationPresiding?.voting_at);
    },

    ifBeforeOrToday() {
      return checkIsDateSameOrBeforeToday(
        this.getCategorizationPresiding?.voting_at
      );
    },

    isAfterToday() {
      return checkIsDateAfterToday(this.getCategorizationPresiding?.voting_at);
    },

    isVoted() {
      return (
        this.getCategorizationPresiding?.is_voted && !this.isFinishedSigned
      );
    },

    votingData() {
      return convertDateToCurrentTimeZone(
        this.getCategorizationPresiding?.voting_at
      ).date;
    },

    getDateConfig() {
      return datePickerConfig;
    },

    isFinishedSigned() {
      return (
        this.getCategorizationPresiding?.voting.status === 'finished_signed'
      );
    },

    isCertificate() {
      return !!this.getCategorizationPresiding?.certificate;
    },

    isAchive() {
      return !!this.getCategorizationPresiding?.archive;
    },
  },

  methods: {
    async getCategorizationStructure() {
      let data = { lang: 'uk', uuid: this.$route.params.id };
      try {
        let res;
        res = await getCategorizationFormStructure(data);
        if (res.status === 200) {
          this.structure = res.data.data.structure;
        }
      } catch (e) {
        console.log('>>> error getCategorizationFormStructure', e.response);
      }
    },

    print() {
      window.print();
    },

    statusModelHandler() {
      this.getStatusModel.forEach((element) => {
        if (
          element.filing_status ===
            this.getCategorizationPresiding.filing_status &&
          element.review_status ===
            this.getCategorizationPresiding.review_status
        ) {
          this.commentsVisible = element.comments_visible;
          this.canComment =
            element.can_comment ||
            (this.ifToday &&
              this.getCategorizationPresiding.review_status ==
                'waiting_for_voting');
          // this.canComment = element.can_comment;
          this.canEdit = element.edit;
          this.voteSchedule = element.schedule;

          if (Array.isArray(element.buttons)) {
            this.buttonsArray = [...element.buttons];
          } else if (this.ifBeforeOrToday) {
            this.buttonsArray = this.getCategorizationPresiding?.is_voted
              ? [...element.buttons.buttonsAfterVoting]
              : [...element.buttons.buttonsDuringVoting];
          } else {
            this.buttonsArray = [...element.buttons.buttonsBeforeVoting];
          }

          // if(element.is_sign) {
          //   this.buttonsArray = [...element.buttons];
          // }
          // else if(this.ifBeforeOrToday) {
          //   this.buttonsArray = this.getCategorizationPresiding?.is_voted
          //     ? [...element.buttons.buttonsAfterVoting]
          //     : [...element.buttons.buttonsDuringVoting];
          // }
          // else {
          //   this.buttonsArray = [...element.buttons.buttonsBeforeVoting]
          // }
        }
      });
    },

    editIsDisabled(uuid) {
      let editIsDisabled = false;
      switch (this.canEdit) {
        case 'can_edit_all_fields':
          editIsDisabled = false;
          break;
        case 'not_allowed':
          editIsDisabled = true;
          break;
        case 'can_edit_commented_fields':
          this.getCommentsHotel[uuid]
            ? (editIsDisabled = false)
            : (editIsDisabled = true);
          break;
      }
      return editIsDisabled;
    },

    populateFormWithData() {
      this.structure.map((el) =>
        el.subgroups.map((s) =>
          s.criterias.map(async (s) => {
            if (s.uuid in this.prefilledCriteris) {
              if (Array.isArray(this.prefilledCriteris[s.uuid].value)) {
                let valueToPopulate = [];

                for (let file in this.prefilledCriteris[s.uuid].value) {
                  let convertedFile = await this.createFile(
                    this.prefilledCriteris[s.uuid].value[file].original_url,
                    this.prefilledCriteris[s.uuid].value[file].mime
                  );
                  valueToPopulate.push(convertedFile);
                }
                s.value = valueToPopulate;
              } else {
                s.value = this.prefilledCriteris[s.uuid].value;
              }
            }
          })
        )
      );
    },

    async getScheduleData() {
      this.votingDate = !!this.getCategorizationPresiding.voting_at
        ? this.getCategorizationPresiding.voting_at
        : this.votingDate;

      this.uploadedSchedule = !!this.getCategorizationPresiding
        ?.schedule_attachment?.length
        ? [
            await this.createFile(
              this.getCategorizationPresiding.schedule_attachment[0]
                .original_url,
              this.getCategorizationPresiding.schedule_attachment[0].mime
            ),
          ]
        : this.uploadedSchedule;
    },

    async createFile(url, type) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: type,
      };
      return new File([data], url.split('/').at(-1), metadata);
    },

    async signAndSendHandler() {
      // this.dataToSend.append(`voting_date`, '2022-04-19 20:00:00');
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dataToSend.append(`uuid`, this.getCategorizationPresiding.uuid);
        this.dataToSend.append(`voting_date`, this.votingDate);
        this.dataToSend.append(`schedule_attachment`, this.uploadedSchedule[0]);
        this.$store.commit(
          'SET_DATA_TO_SIGN',
          this.getCategorizationPresiding.uuid
        );
        this.$store.dispatch('setVotingDatePresiding', {
          data: this.dataToSend,
          isSign: true,
        });
      }

      // await this.$store.dispatch(
      //   'signVotingDatePresiding',
      //   this.getCategorizationPresiding.uuid
      // );
    },

    changeDateHandler() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dataToSend.append(`uuid`, this.getCategorizationPresiding.uuid);
        this.dataToSend.append(`voting_date`, this.votingDate);
        this.dataToSend.append(`schedule_attachment`, this.uploadedSchedule[0]);
      }

      this.$store.dispatch('setVotingDatePresiding', {
        data: this.dataToSend,
        isSign: false,
      });
    },

    preliminaryResultsHandler() {
      this.$router.push({
        name: 'presiding-commissioner_voting-results',
        params: {
          id: this.getCategorizationPresiding.voting.uuid,
          categorization_id: this.getCategorizationPresiding.uuid,
        },
      });
    },

    voteHandler() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'vote',
        size: 'xxl',
        handler: {
          payload: this.getCategorizationPresiding.voting.uuid,
          role: 'Presiding',
        },
      });
    },

    disabledDates(date) {
      return new Date(date).getTime() < Date.now();
    },

    groupGrid(group) {
      if (group.criterias[0].type == 'images') {
        return 'image__grid';
      }
      return 'profile__grid';
    },

    downloadCertificate() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationPresiding.certificate.attachment.original_url
      );
    },

    downloadPDF() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationPresiding.archive.original_url
      );
    },
  },

  validations() {
    return {
      uploadedSchedule: {
        required,
      },
      votingDate: {
        required,
      },
    };
  },
};
</script>

<style lang="sass" scoped>
.voting-date__block
  display: flex
  align-items: center
  flex-direction: column
  margin-top: 4rem
.schedule__upload
  max-width: 40rem
  margin: 7rem 0
.mx-datepicker
  max-width: 40rem
.control__btn-wrapper
  margin-top: 8rem
.profile__info
  margin-bottom: 50px
.profile__info-title
  margin-bottom: 20px
.profile__link
  color: $gray
  cursor: pointer
  transition: color 0.15s
  &:hover
    color: $gray_active
    transition: color 0.25s
    text-decoration: underline
</style>
